import React, { useEffect, useState } from "react";
// import Iframe from 'react-iframe';

const PdfFlipbook = ({url,lang}) => {
	const [url1, seturl] = useState("");
	useEffect(() => {
		const v = url;
		const dynamic = `./pdf-viewer/external/pdfjs-2.1.266-dist/web/viewer.html?book=${v}&lang=${lang}`;
		// const dynamic = `./pdf-viewer/external/pdfjs-2.1.266-dist/web/viewer.html?book=${v}`;
		//props.history.replace("/");
		seturl(dynamic);
	}, []);
	return(
	  // <Iframe  src={url1} width="99%" height="621"/>
		<div className="iframe-container">
			<iframe id="iframe_id" src={url1} book={url} lang={lang} title="pdf"></iframe>
		</div>
	)
};

export default PdfFlipbook;
