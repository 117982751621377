import React, { useEffect, useState } from "react";

import { PdfFlipbook } from './lib'
// import logo from './logo.svg';
import './App.css';

function App() {
// const [seturl] = useState("");
//const id = props.match.params.book;
const id = new URLSearchParams(window.location.search).get("book");
const lang = new URLSearchParams(window.location.search).get("language");
const [loading, setLoading] = useState(false);
// const [ids,setids] = useState(id);
// const [langs,setlang] = useState(lang);
const [ids] = useState(id);
const [langs] = useState(lang);
useEffect(() => {
		if (id) {
			//setids(id);
			//setlang(lang);
			window.history.pushState("","","https://pdf.joyread.club");
			setLoading(true);
		}
		else{
			window.location.href="https://joyread.club/";
		}
}, []);

  return loading ? (
    <div className="App">
		<PdfFlipbook url={ids} lang={langs}/>
    </div>
  ):null;
}
export default App;
